import { ApolloClient } from '@apollo/client';
import { GetDefaultCompany } from './api/CompaniesApi';
import { GetDefaultTimeZone } from './api/TaskPropertiesApi';

interface MicroUiConfig {
  siteUrl: string;
  microUiUrl: string;
  microUiFilePath: string;
  integratedFilePath: string;
}
interface AutomationPortalConfig {
  baseUrl: string;
  pipelineRunsRoute: string;
}

interface IConfig {
  [settings: string]: {
    appBuildId: string;
    azureAdAppClientId: string;
    azureAdScopeUriPrefix: string;
    baseURI: string;
    appInsightsInstrumentationKey: string;
    localStorageVersion: string;
    euaUrl: string;
    maxExportTaskSize: string;
    reconIntelligenceConfig: MicroUiConfig;
    statConfig: MicroUiConfig;
    jemConfig: MicroUiConfig;
    closeCheckListConfig: MicroUiConfig;
    exaConfig: MicroUiConfig;
    gsrConfig: MicroUiConfig;
    automationPortalConfig: AutomationPortalConfig;
  };
}
const config: IConfig = {} as IConfig;
const loadConfiguration = (): Promise<void> => {
  const configVersion = '13698133';
  let configPath = `/config${configVersion}.json`;

  if (configVersion.indexOf('##') === 0) {
    configPath = '/config.json';
  }

  return fetch(configPath)
    .then((result) => result.json())
    .then((newConfig: IConfig) => {
      Object.keys(newConfig).forEach((keyName) => {
        config[keyName] = newConfig[keyName];
      });
    });
};
// We can use this cached query until our needs for default company change
const setDefaultCompany = (client: ApolloClient<unknown>) => {
  client.writeQuery({
    query: GetDefaultCompany,
    data: {
      defaultCompany: {
        __typename: 'CompanyDto',
        companyCode: 1010,
        countryCode: 'US',
        name: 'Microsoft',
      },
    },
  });
};
const setDefaultTimeZone = (client: ApolloClient<unknown>) => {
  client.writeQuery({
    query: GetDefaultTimeZone,
    data: {
      defaultTimeZone: {
        __typename: 'TaskPropertyValueDto',
        id: 1000,
        name: 'IST',
        isActive: 1,
        modifiedOn: '2022-10-10 07:00:00.0000000',
        modifiedBy: {
          graphGuid: '113F3B23-8227-42E6-892A-DAF11179447A',
          displayName: '',
          alias: '',
          emailAddress: '',
        },
      },
    },
  });
};
export default config;
export { loadConfiguration, setDefaultCompany, setDefaultTimeZone };
